// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./r7hfPwOBa-0.js";

const cycleOrder = ["yCjmyPjzT"];

const serializationHash = "framer-z15Fp"

const variantClassNames = {yCjmyPjzT: "framer-v-1v7qzsr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, tap, title, width, ...props}) => { return {...props, d_UuBGiDW: title ?? props.d_UuBGiDW ?? "item 4", xUcfLs1N7: tap ?? props.xUcfLs1N7} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, d_UuBGiDW, xUcfLs1N7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yCjmyPjzT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1cjetki = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (xUcfLs1N7) {
const res = await xUcfLs1N7(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1v7qzsr", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"yCjmyPjzT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1cjetki} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "500"}}>item 4</motion.p></React.Fragment>} className={"framer-10jy6h0"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"ZCV_daG_4"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={d_UuBGiDW} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-z15Fp[data-border=\"true\"]::after, .framer-z15Fp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z15Fp.framer-z4ib1f, .framer-z15Fp .framer-z4ib1f { display: block; }", ".framer-z15Fp.framer-1v7qzsr { cursor: pointer; height: 19px; overflow: visible; position: relative; width: 48px; }", ".framer-z15Fp .framer-10jy6h0 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"d_UuBGiDW":"title","xUcfLs1N7":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerr7hfPwOBa: React.ComponentType<Props> = withCSS(Component, css, "framer-z15Fp") as typeof Component;
export default Framerr7hfPwOBa;

Framerr7hfPwOBa.displayName = "item-menu";

Framerr7hfPwOBa.defaultProps = {height: 19, width: 48};

addPropertyControls(Framerr7hfPwOBa, {d_UuBGiDW: {defaultValue: "item 4", displayTextArea: false, title: "Title", type: ControlType.String}, xUcfLs1N7: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerr7hfPwOBa, [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}])